import { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import Navbar from '../layouts/navBar';
import Footer from '../layouts/Footer';

function ThankYou() {
  const [searchParams] = useSearchParams();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const firstName = searchParams.get('firstName');
    const lastName = searchParams.get('lastName');
    const amount = searchParams.get('amount');
    const date = searchParams.get('date');
    const title = searchParams.get('title');
    const status = searchParams.get('status');

    if (status === 'error') {
      setErrorMessage(
        'Something went wrong with your transaction. Please try again.'
      );
    } else if (amount && date && title && firstName && lastName) {
      setTransactionDetails({
        firstName,
        lastName,
        amount,
        date,
        title,
      });
    }
  }, [searchParams]);

  return (
    <div className='bg-[#FFE4B5] min-h-screen flex flex-col'>
      <Navbar />
      <div className='container mx-auto flex-grow flex flex-col items-center justify-center text-center py-16'>
        {errorMessage ? (
          <>
            <h1 className='text-red-500 font-montserratExtrabold text-6xl mb-4'>
              Oops!
            </h1>
            <p className='text-red-500 font-montserratExtrabold text-lg mb-8'>
              {errorMessage}
            </p>
          </>
        ) : (
          <>
            <h1 className='text-[#62A0E3] font-montserratExtrabold text-6xl mb-4'>
              Thank You, {transactionDetails?.firstName}{' '}
              {transactionDetails?.lastName}!
            </h1>
            <p className='text-[#35C5C0] font-montserratExtrabold text-lg mb-8'>
              Your transaction has been successfully processed. We are thrilled
              to have you onboard!
            </p>

            {transactionDetails ? (
              <div className='bg-white shadow-lg rounded-lg p-8 mb-8 w-full max-w-lg'>
                <h2 className='text-[#62A0E3] font-montserratExtrabold text-3xl mb-6'>
                  Transaction Details
                </h2>
                <p className='font-montserratExtrabold text-lg mb-2'>
                  <span className='font-bold'>Amount Paid:</span> $
                  {parseFloat(transactionDetails.amount).toFixed(2)}
                </p>
                <p className='font-montserratExtrabold text-lg mb-2'>
                  <span className='font-bold'>Date:</span>{' '}
                  {new Date(transactionDetails.date).toLocaleDateString(
                    'en-GB',
                    {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    }
                  )}
                </p>
                <p className='font-montserratExtrabold text-lg mb-2'>
                  <span className='font-bold'>Subscription Tier:</span>{' '}
                  {transactionDetails.title}
                </p>
              </div>
            ) : (
              <p>Loading transaction details...</p>
            )}
          </>
        )}

        <div className='mt-8'>
          <Link
            to={errorMessage ? '/pricing' : '/dashboard'}
            className='bg-[#62A0E3] text-white font-montserratBold py-3 px-6 rounded-full hover:scale-105 transition-transform duration-300'
          >
            {errorMessage ? 'Go to Pricing' : 'Go to Dashboard'}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ThankYou;
