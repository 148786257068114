import plus from '../../assets/plus.png';
import minus from '../../assets/minus.png';
import { useState } from 'react';
import faqs from '../../assets/Faqs.png';
export const sections = [
  {
    title: 'What is Morning Mystery?',
    description:
      'Morning Mystery is a service that sends engaging STEM-related text messages to parents’ phones, tailored to their child’s age group. Each morning and evening, your child will receive fun and educational content designed to spark curiosity and encourage learning.',
  },
  {
    title: 'How does the service work?',
    description:
      'Sign up by providing your details and your child’s age. You’ll start receiving daily STEM messages that are age-appropriate, with morning texts introducing new concepts and evening texts reinforcing what was learned with interactive questions and activities',
  },
  {
    title: 'What age groups do you cater to?',
    description:
      'We provide content for three age groups: 3-4 years old, 5-6 years old, and 7-8 years old. Each age bracket receives tailored messages that are appropriate for their developmental stage.',
  },
  {
    title: 'Are the messages interactive?',
    description:
      'Yes! Our messages are designed to engage your child with questions and activities that encourage them to think, explore, and discuss what they’ve learned with you.',
  },
  {
    title: 'Is there a cost for the service?',
    description:
      'We offer various pricing plans to suit different needs. Please visit our pricing page or contact us directly for detailed information on our subscription options.',
  },
  {
    title: 'How can I sign up?',
    description:
      'Simply visit our Sign Up page, enter your details, and choose your child’s age bracket. You’ll start receiving messages right away!',
  },
  {
    title: 'Can I cancel my subscription?',
    description:
      'Yes, you can cancel your subscription at any time. Just log in to your account and follow the instructions to cancel, or contact our support team for assistance.',
  },
  {
    title: 'How do you ensure the content is age-appropriate?',
    description:
      'Our team of educators and child development experts carefully crafts content to ensure it is suitable and engaging for each age group, based on developmental milestones and educational standards.',
  },
  {
    title: 'How do I contact support if I have more questions?',
    description:
      'You can reach our support team through the Contact Us page on our website, or email us directly at hello@morningmystery.me',
  },
];

const FAQS = () => {
  const [openSections, setOpenSections] = useState(
    sections.map((_, index) => !index)
  );

  const toggleSection = (index) => {
    setOpenSections((prev) =>
      prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index]
    );
  };

  return (
    <div className="bg-[#F27253]">
      <div className=" container mx-auto md:py-20 py-10 px-6 lg:px-4">
        <div className="grid md:grid-cols-12 grid-cols-6 md:gap-16 gap-8">
          <div className="md:col-span-5  col-span-6">
            <h1 className="text-6xl font-montserratExtrabold md:block hidden text-white">FAQ’s</h1>
            <img src={faqs} alt="faqs" className="md:w-96  w-40  md:mt-10  mt-5 md:block hidden" />
            <div className="md:hidden  flex justify-between items-center w-full">
              <h1 className="text-6xl font-montserratExtrabold text-white">FAQ’s</h1>
              <img src={faqs} alt="faqs" className="w-28" />
            </div>
          </div>

          <div className="md:col-span-7   col-span-6">
            {sections.map((section, index) => {
              const { title, description } = section;
              return (
                <div
                  key={index}
                  className={`mb-5 pb-5 ${index === sections.length - 1 ? '' : 'border-b-4'}`}
                >
                  <div
                    className="flex items-center space-x-2 py-4 cursor-pointer"
                    onClick={() => toggleSection(index)}
                  >
                    <div className="flex items-center justify-center w-10 h-10  flex-shrink-0">
                      <span className="text-2xl font-bold">
                        {openSections.includes(index) ?

                          <img src={minus} />
                          :
                          <img src={plus} />
                        }
                      </span>
                    </div>
                    <h1 className="text-white font-montserratExtrabold md:text-4xl text-2xl">
                      {title}
                    </h1>
                  </div>
                  {openSections.includes(index) && (
                    <p className="text-white text-base font-montserratBold mt-4 mb-4 pl-12">
                      {description}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQS;
