import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import authService from '../services/auth-service';
import toast from 'react-hot-toast';
import { FiLogOut } from 'react-icons/fi';

function Navbar() {
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setIsSticky(true);
      } else if (window.scrollY <= 0) {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if (accessToken || refreshToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    await authService.logout();

    toast.success('Logged out successfully.');

    setTimeout(() => {
      navigate('/login');
    }, 2000);
  };

  return (
    <>
      <div
        className={`z-20 bg-[#FFE4B5] navbar ${
          isSticky ? 'fixed fsd w-full top-0' : 'nfsd'
        }`}
      >
        <div className='flex justify-between items-center md:pb-2 px-6 pb-4 px-4'>
          <NavLink to='/'>
            <img src={logo} alt='Logo' className='md:w-64 w-48' />
          </NavLink>
          <div className='hidden lg:flex space-x-12 items-center'>
            <NavLink
              to='/'
              className={`font-montserratBold text-2xl uppercase cursor-pointer`}
            >
              Home
            </NavLink>
            <a
              href='/#pricing'
              className={`font-montserratBold text-2xl uppercase cursor-pointer`}
            >
              Pricing
            </a>
            <a
              href='/#howwork'
              className={`font-montserratBold text-2xl uppercase cursor-pointer`}
            >
              HOW IT WORKS
            </a>
            {isLoggedIn ? (
              <>
                <NavLink
                  to='/dashboard'
                  className={`font-montserratBold text-2xl uppercase cursor-pointer`}
                >
                  Dashboard
                </NavLink>
                <NavLink
                  className={`font-montserratBold text-2xl uppercase cursor-pointer`}
                  onClick={handleLogout}
                >
                  <FiLogOut size={30} />
                </NavLink>
              </>
            ) : (
              <NavLink
                to='/login'
                className={`font-montserratBold text-2xl uppercase cursor-pointer`}
              >
                SIGN UP/LOGIN
              </NavLink>
            )}
          </div>
          <div className='lg:hidden'>
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className='text-black focus:outline-none'
            >
              <svg
                className='w-9 h-9'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      {menuOpen && (
        <div className='fixed inset-0 z-50 bg-black bg-opacity-50'>
          <div className='absolute h-full left-0 w-64 bg-[#FFE4B5] shadow-md'>
            <div className='flex justify-between p-4 border-b-2 border-[#FF5F6D]'>
              <NavLink to='/'>
                <img src={logo} alt='Logo' className='w-32' />
              </NavLink>
              <button
                onClick={() => setMenuOpen(false)}
                className='text-black focus:outline-none'
              >
                <svg
                  className='w-4 h-4'
                  fill='#000000'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            <div className='flex flex-col justify-start items-center space-y-4 py-4'>
              <NavLink
                to='/'
                className={`font-montserratBold text-base uppercase cursor-pointer`}
              >
                Home
              </NavLink>
              <a
                href='#pricing'
                className={`font-montserratBold text-base uppercase cursor-pointer`}
              >
                Pricing
              </a>
              <a
                href='#howwork'
                className={`font-montserratBold text-base uppercase cursor-pointer`}
              >
                HOW IT WORKS
              </a>
              {isLoggedIn ? (
                <>
                  <NavLink
                    to='/dashboard'
                    className={`font-montserratBold text-base uppercase cursor-pointer`}
                  >
                    Dashboard
                  </NavLink>
                  <NavLink
                    className={`font-montserratBold text-base uppercase cursor-pointer`}
                    onClick={handleLogout}
                  >
                    Logout
                  </NavLink>
                </>
              ) : (
                <NavLink
                  to='/login'
                  className={`font-montserratBold text-base uppercase cursor-pointer`}
                >
                  SIGN UP/LOGIN
                </NavLink>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
