import heroBg from '../assets/heroBg.png';
import QuestionSection from '../components/HomePage/QuestionSection';
import WorkingBlock from '../components/HomePage/WorkingBlock';
import EducationSystem from '../components/HomePage/EductionSystem';
import ImpactEducation from '../components/HomePage/ImpactEducation';
import FAQS from '../components/HomePage/Faqs';
import FunFact from '../components/HomePage/FunFacts';
import Blogs from '../components/HomePage/Blogs';
import SignBlock from '../components/HomePage/SignSec.js';
import Footer from '../layouts/Footer';
import Navbar from '../layouts/navBar.js';
import { Link } from 'react-router-dom';
import Pricing from '../components/HomePage/pricing';
function Home() {
  return (
    <>
      <div className='bg-[#FFE4B5]' style={{ paddingBottom: '20px' }}>
        <Navbar />
        <div className='relative h-auto lg:h-[650px]'>
          <div className='md:p-8 p-4'>
            <p className='font-montserratBold md:text-4xl text-2xl'>
              START YOUR CHILD’S{' '}
            </p>
            <p className='font-montserratBold md:text-4xl text-2xl'>
              MORNING WITH
            </p>
            <p className='font-montserratBold md:text-4xl text-2xl'>WONDER</p>
          </div>
          <div className='bg-[#FF5F6D] md:p-8 p-4 lg:w-4/5 rounded-tr-[60px] rounded-br-[60px] w-3/5 md:block flex justify-between'>
            <div className=''>
              <p className='font-montserratBold md:text-6xl text-2xl text-white'>
                DAILY STEM{' '}
              </p>
              <p className='font-montserratBold md:text-6xl text-2xl text-white'>
                ADVENTURES{' '}
              </p>
              <p className='font-montserratBold md:text-6xl text-2xl text-white'>
                FOR KIDS
              </p>
            </div>
          </div>
          <div className=''>
            <Link
              to='/register'
              style={{ display: 'inline-block' }}
              className={`bg-[#62A0E3] mt-8  rounded-full text-white font-montserratBold md:text-2xl text-lg px-6  ml-8 py-3 `}
            >
              SIGN UP
            </Link>
          </div>
          <img
            src={heroBg}
            alt='Background'
            className='lg:absolute lg:top-0 lg:right-0 w-full h-full object-cover lg:w-[800px] 1320:w-[1000px] 1320:h-[650px] max-auto bottom-0'
          />
        </div>
      </div>
      <QuestionSection />
      <Pricing />
      <WorkingBlock />
      <EducationSystem />
      <ImpactEducation />
      <FAQS />
      <FunFact />
      <Blogs />
      <SignBlock />
      <Footer />
      {/*
       */}
    </>
  );
}

export default Home;
