import axios from './axios';

const subscribe = async (data) => {
  try {
    const response = await axios.post('/stripe/subscribe', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const manage = async () => {
  try {
    const response = await axios.post('/stripe/manage');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const stripeService = {
  subscribe,
  manage,
};

export default stripeService;
