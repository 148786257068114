import axios from './axios';

const login = async (email, password) => {
  try {
    const response = await axios.post(`/auth`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const logout = async () => {
  try {
    const response = await axios.post(`/auth/logout`, {});

    localStorage.removeItem('user');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const authService = {
  login,
  logout,
};

export default authService;
