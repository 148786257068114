
const FunFact = () => {
    return (
        <div>
            <div className="bg-[#62A0E3] pt-12 ">
                <div className=" py-12">
                    <h1 className="font-montserratBold md:font-montserratBold font-bold text-center text-white md:text-6xl container mx-auto  md:w-[62%]   text-4xl leading-snug">
                        “What a fun and engaging way to start the morning! My little girl
                        remembers her Morning Mystery and looks forward to her afternoon
                        text! What a fun concept!”
                    </h1>
                    <div className="text-center font-montserratBold text-white pt-8 ">
                        <h3 className="text-3xl ">Sarah Macelod</h3>
                        <h4>Mom of 4 year old Leo</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunFact;
