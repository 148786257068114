import { useEffect, useState } from 'react';
import priceImage from '../../assets/pricing.png';
import Slider from 'react-slick';
import subscriptionService from '../../services/subscription-service';
import { useNavigate } from 'react-router-dom';
import stripeService from '../../services/stripe-service';
import userService from '../../services/user-service';
import toast from 'react-hot-toast';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768, // Small screens
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024, // Medium screens
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Pricing = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [basic, setBasic] = useState({});
  const [standard, setStandard] = useState({});
  const [pro, setPro] = useState({});
  const [isSubscribing, setIsSubscribing] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem('access-token');
      const refreshToken = localStorage.getItem('refresh-token');

      if (!accessToken && !refreshToken) {
        return;
      }

      try {
        const response = await userService.getMyUser();
        if (response?.error) {
          toast.error(response?.error);
        } else {
          setUser(response);
          setIsSubscribed(response.isSubscribed);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    const fetchSubscriptions = async () => {
      try {
        const data = await subscriptionService.getSubscriptions();

        data.forEach((subscription) => {
          if (subscription.title === 'Basic') {
            setBasic(subscription);
          } else if (subscription.title === 'Standard') {
            setStandard(subscription);
          } else if (subscription.title === 'Pro') {
            setPro(subscription);
          }
        });
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchSubscriptions();
    fetchUser();
  }, []);

  const handleSubscribe = async (priceId) => {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if (!accessToken && !refreshToken) {
      navigate('/login');
      return;
    }

    setIsSubscribing((prevState) => ({ ...prevState, [priceId]: true }));

    try {
      const response = await stripeService.subscribe({ priceId });
      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success(`${response?.message}. Redirecting...`);

        setTimeout(() => {
          window.location.href = response.paymentLink;
        }, 2000);
      }
    } catch (error) {
      console.error('Error during subscription:', error);
      toast.error('An error occurred during subscription');
    } finally {
      setIsSubscribing((prevState) => ({ ...prevState, [priceId]: false }));
    }
  };

  const handleManageSubscription = async () => {
    try {
      const response = await stripeService.manage();
      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success(`${response?.message}. Redirecting...`);

        setTimeout(() => {
          window.location.href = response.portalLink;
        }, 2000);
      }
    } catch (error) {
      console.error('Error during manage subscription:', error);
      toast.error('An error occurred during manage subscription');
    }
  };

  const isSubscribedTo = (subscriptionId) => {
    return user?.isSubscribed && user?.subscriptionId === subscriptionId;
  };

  return (
    <div id='pricing' className='bg-[#FFE4B5] py-16 flex flex-col'>
      <div className='container mx-auto flex-grow flex flex-col justify-center'>
        <h1 className='text-[#62A0E3] md:text-6xl text-[48px] font-montserratExtrabold flex justify-start '>
          Pricing Plan
        </h1>
        <div className='md:block hidden'>
          <div className='grid grid-cols-1 md:grid-cols-3 md:gap-8 gap-4 mt-8 '>
            <div className='flex justify-center items-end relative lg:mb-0 mb-8'>
              <div className='bg-[#FF7799] flex flex-col justify-between  items-center rounded-tl-full rounded-tr-full h-full mt-5 w-80 hover:scale-105 transform transition-all duration-300 hover:z-10 '>
                {/* hover:scale-105 transform transition-all duration-300 hover:z-10 */}
                <div className='text-center'>
                  <p className='text-white font-montserratBold   text-5xl  mt-32'>
                    Basic Tier
                  </p>
                  <div className='text-white text-center lg:text-4xl text-2xl'>
                    <h2 className='font-montserratBold   px-7 pt-4'>
                      $9. <sup>99</sup>
                      <span className='text-base'>/ month</span>
                    </h2>
                    <p className='font-montserratExtrabold text-lg   lg:px-14 py-7 px-4'>
                      Texts per Week: 3 days (morning texts only).
                    </p>
                    <p className='font-montserratExtrabold text-lg   lg:px-14 py-7 px-4'>
                      Content Type: STEM facts with a simple interactive
                      question or activity.
                    </p>
                  </div>
                </div>
                {isSubscribedTo(basic.id) && (
                  <button
                    className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                    onClick={handleManageSubscription}
                  >
                    Manage Subscription
                  </button>
                )}
                {!isSubscribed && (
                  <button
                    className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                    onClick={() => handleSubscribe(basic.priceId)}
                    disabled={isSubscribing[basic.priceId] || false}
                  >
                    {isSubscribing[basic.priceId]
                      ? 'SUBSCRIBING...'
                      : 'SUBSCRIBE'}
                  </button>
                )}
              </div>
            </div>

            <div className='flex justify-center items-end   lg:mb-0 mb-8'>
              <div className='bg-[#35C5C0] flex flex-col justify-between  items-center rounded-tl-full rounded-tr-full h-full  w-80 hover:scale-105 transform transition-all duration-300 hover:z-10  '>
                <div className='text-center'>
                  <h2 className='text-white font-montserratBold  text-5xl mt-24'>
                    Standard Tier
                  </h2>
                  <div className='text-white  text-center lg:text-4xl text-2xl'>
                    <h2 className='font-montserratBold   px-7 pt-4'>
                      $12. <sup>99</sup>
                      <span className='text-base'>/ month</span>
                    </h2>
                    <p className='font-montserratExtrabold text-lg font-bold  lg:px-12 py-7 px-12'>
                      Texts per Week: 5 days (morning and evening texts).
                    </p>
                    <p className='font-montserratExtrabold text-sm font-bold  lg:px-12 py-2 px-12'>
                      Content Type: Interactive STEM concepts with questions
                      that promote collaboration between parent and child.
                      Includes activities designed to encourage critical
                      thinking, teamwork, and hands-on learning experiences.
                    </p>
                  </div>
                </div>
                {isSubscribedTo(standard.id) && (
                  <button
                    className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                    onClick={handleManageSubscription}
                  >
                    Manage Subscription
                  </button>
                )}
                {!isSubscribed && (
                  <button
                    className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                    onClick={() => handleSubscribe(standard.priceId)}
                    disabled={isSubscribing[standard.priceId] || false}
                  >
                    {isSubscribing[standard.priceId]
                      ? 'SUBSCRIBING...'
                      : 'SUBSCRIBE'}
                  </button>
                )}
              </div>
            </div>

            <div className='flex justify-center items-end  relative  lg:mb-0 mb-8 '>
              <div className='absolute lg:right-16 lg:-top-[92px]  hidden lg:flex justify-center items-start '>
                <img src={priceImage} alt={priceImage} className='w-36' />
              </div>

              <div className='bg-[#62A0E3] flex flex-col justify-between  items-center rounded-tl-full rounded-tr-full h-full w-80 -z-0 hover:scale-105 transform transition-all duration-300 hover:z-10 '>
                <div className='text-center'>
                  <h2 className='text-white font-montserratBold   text-5xl  mt-16'>
                    Pro Tier
                  </h2>
                  <div className='text-white  text-center lg:text-4xl text-2xl'>
                    <h2 className='font-montserratBold   px-7 pt-4'>
                      $14. <sup>99</sup>{' '}
                      <span className='text-base'>/ month</span>
                    </h2>
                    <p className='font-montserratExtrabold    text-lg font-bold  lg:px-14 py-7 px-4'>
                      Texts per Week: 7 days (morning and evening texts).
                    </p>
                    <p className='font-montserratExtrabold   text-lg font-bold  lg:px-12  px-4'>
                      Content Type: In-depth STEM concepts, creative challenges,
                      and engaging activities that stimulate deeper critical
                      thinking and independent exploration.
                    </p>
                  </div>
                </div>
                {isSubscribedTo(pro.id) && (
                  <button
                    className='bg-[#F27253] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                    onClick={handleManageSubscription}
                  >
                    Manage Subscription
                  </button>
                )}
                {!isSubscribed && (
                  <button
                    className='bg-[#F27253] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                    onClick={() => handleSubscribe(pro.priceId)}
                    disabled={isSubscribing[pro.priceId] || false}
                  >
                    {isSubscribing[pro.priceId]
                      ? 'SUBSCRIBING...'
                      : 'SUBSCRIBE'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* small device */}
        <div className='md:hidden block'>
          <div className='container mx-auto mt-8'>
            <Slider {...settings}>
              <div className='flex justify-center items-end  mb-8 px-4'>
                <div className='bg-[#FF7799] flex flex-col justify-between items-center rounded-tl-full rounded-tr-full h-full min-h-[42rem] max-w-60 mas  '>
                  <div className='text-center'>
                    <h2 className='text-white font-montserratBold text-5xl mt-24'>
                      Basic Tier
                    </h2>
                    <div className='text-white text-center lg:text-4xl text-4xl'>
                      <h2 className='font-montserratBold px-7 pt-4'>
                        $9. <sup>99</sup>
                        <span className='text-base'>/ month</span>
                      </h2>
                      <p className='font-montserratExtrabold text-lg   lg:px-14 py-7 px-4'>
                        Texts per Week: 3 days (morning texts only).
                      </p>
                      <p className='font-montserratExtrabold text-lg   lg:px-14 py-7 px-4'>
                        Content Type: STEM facts with a simple interactive
                        question or activity.
                      </p>
                    </div>
                  </div>
                  {isSubscribedTo(basic.id) && (
                    <button
                      className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                      onClick={handleManageSubscription}
                    >
                      Manage Subscription
                    </button>
                  )}
                  {!isSubscribed && (
                    <button
                      className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                      onClick={() => handleSubscribe(basic.priceId)}
                      disabled={isSubscribing[basic.priceId] || false}
                    >
                      {isSubscribing[basic.priceId]
                        ? 'SUBSCRIBING...'
                        : 'SUBSCRIBE'}
                    </button>
                  )}
                </div>
              </div>

              <div className='flex justify-center items-end mb-8 px-4'>
                <div className='bg-[#35C5C0] flex flex-col justify-between items-center rounded-tl-full rounded-tr-full h-full min-h-[42rem] max-w-60 mas '>
                  <div className='text-center'>
                    <h2 className='text-white font-montserratBold text-5xl mt-24'>
                      Standard Tier
                    </h2>
                    <div className='text-white text-center  text-3xl'>
                      <h2 className='font-montserratBold px-7 pt-4'>
                        $12. <sup>99</sup>
                        <span className='text-base'>/ month</span>
                      </h2>
                      <p className='font-montserratExtrabold text-lg font-bold  lg:px-12 py-7 px-12'>
                        Texts per Week: 5 days (morning and evening texts).
                      </p>
                      <p className='font-montserratExtrabold text-sm font-bold  lg:px-12 py-2 px-12'>
                        Content Type: Interactive STEM concepts with questions
                        that promote collaboration between parent and child.
                        Includes activities designed to encourage critical
                        thinking, teamwork, and hands-on learning experiences.
                      </p>
                    </div>
                  </div>
                  {isSubscribedTo(standard.id) && (
                    <button
                      className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                      onClick={handleManageSubscription}
                    >
                      Manage Subscription
                    </button>
                  )}
                  {!isSubscribed && (
                    <button
                      className='bg-[#62A0E3] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                      onClick={() => handleSubscribe(standard.priceId)}
                      disabled={isSubscribing[standard.priceId] || false}
                    >
                      {isSubscribing[standard.priceId]
                        ? 'SUBSCRIBING...'
                        : 'SUBSCRIBE'}
                    </button>
                  )}
                </div>
              </div>

              <div className='flex justify-center items-end relative mb-8 px-4'>
                <div className='absolute lg:right-16 lg:-top-[92px] hidden lg:flex justify-center items-start'>
                  <img src={priceImage} alt='Pro Tier' className='w-36' />
                </div>
                <div className='bg-[#62A0E3] flex flex-col justify-between items-center rounded-tl-full rounded-tr-full h-full min-h-[42rem] max-w-60 mas lg:absolute -z-0  '>
                  <div className='text-center'>
                    <h2 className='text-white font-montserratBold text-5xl mt-16'>
                      Pro Tier
                    </h2>
                    <div className='text-white text-center lg:text-4xl text-3xl'>
                      <h2 className='font-montserratBold px-7 pt-4'>
                        $14. <sup>99</sup>
                        <span className='text-base'>/ month</span>
                      </h2>
                      <p className='font-montserratExtrabold    text-lg font-bold  lg:px-14 py-7 px-4'>
                        Texts per Week: 7 days (morning and evening texts).
                      </p>
                      <p className='font-montserratExtrabold   text-lg font-bold  lg:px-12  px-4'>
                        Content Type: In-depth STEM concepts, creative
                        challenges, and engaging activities that stimulate
                        deeper critical thinking and independent exploration.
                      </p>
                    </div>
                  </div>
                  {isSubscribedTo(pro.id) && (
                    <button
                      className='bg-[#F27253] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                      onClick={handleManageSubscription}
                    >
                      Manage Subscription
                    </button>
                  )}
                  {!isSubscribed && (
                    <button
                      className='bg-[#F27253] flex justify-center w-40 mb-6 items-center text-white font-montserratBold px-4 py-2 mt-2 rounded-full'
                      onClick={() => handleSubscribe(pro.priceId)}
                      disabled={isSubscribing[pro.priceId] || false}
                    >
                      {isSubscribing[pro.priceId]
                        ? 'SUBSCRIBING...'
                        : 'SUBSCRIBE'}
                    </button>
                  )}
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
