const EducationSystem = () => {
    return (
        <div className="">
            <div className="bg-[#FFE4B5]">
                <div>
                    <h1 className="text-[#FF5F6D] font-montserratExtrabold flex text-center justify-center md:py-16 py-8 px-12 md:px-24   md:text-7xl text-4xl ">
                        STEM Education Through Text
                    </h1>
                </div>
                <div className="container mx-auto pb-24">
                    <div className="grid md:grid-cols-2  grid-cols-1 justify-center gap-12 md:px-0 px-4">
                        <div  >
                            <h3 className="text-[#62A0E3] font-montserratExtrabold tex-5xl flex justify-center py-4">
                                Morning Age 2-4
                            </h3>
                            <div className="relative hover:scale-105 transform transition-all duration-300 hover:z-10">
                                <div className="  font-montserrat font-bold bg-white  rounded-md  px-8 py-3 card-content">
                                    Hi Sarah! ☀️ Today’s **Morning Mystery** is about fluffy clouds! ☁️ Did you know they're made of tiny water droplets? Can you pretend to blow on a cotton ball and see if you can make it float like a cloud? (Bonus Fact: Some clouds are so high up, they're actually made of ice!)

                                </div>
                                <div className="speech-bubble-tip">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.353"
                                        height="65"
                                    >
                                        <path
                                            fill="#fff"
                                            fillRule="evenodd"
                                            d="M59.061 0S48.456 47.001 0 65c0 0 52.672-1.999 103.353-56L59.061 0z"
                                            data-name="Trazado 170"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div  >
                            <h3 className="text-[#62A0E3] font-montserratExtrabold tex-5xl flex justify-center py-4">
                                Afternoon Age 2-4
                            </h3>
                            <div className="relative hover:scale-105 transform transition-all duration-300 hover:z-10">
                                <div className="  font-montserrat font-bold bg-white  rounded-md  px-8 py-3 card-content">
                                    Hello Sarah!** 🌈 How did your cloud experiment go? Did you see the cotton ball float? What else do you think is made of tiny drops of water? Maybe rainbows? (Bonus: Rainbows are made when sunlight passes through raindrops!)
                                </div>
                                <div className="speech-bubble-tip">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.353"
                                        height="65"
                                    >
                                        <path
                                            fill="#fff"
                                            fillRule="evenodd"
                                            d="M59.061 0S48.456 47.001 0 65c0 0 52.672-1.999 103.353-56L59.061 0z"
                                            data-name="Trazado 170"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div  >
                            <h3 className="text-[#62A0E3] font-montserratExtrabold tex-5xl flex justify-center py-4">
                                Morning Age 5-6
                            </h3>
                            <div className="relative hover:scale-105 transform transition-all duration-300 hover:z-10">
                                <div className="  font-montserrat font-bold bg-white  rounded-md  px-8 py-3 card-content">
                                    Good morning, Liz! 🌟 Today’s Morning Mystery is about the sun! Did you know the sun is a huge ball of hot gas? It gives us light and warmth. Can you draw a picture of the sun and what it might look like up close? (Bonus Fact: The sun is 93 million miles away from Earth!)</div>
                                <div className="speech-bubble-tip">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.353"
                                        height="65"
                                    >
                                        <path
                                            fill="#fff"
                                            fillRule="evenodd"
                                            d="M59.061 0S48.456 47.001 0 65c0 0 52.672-1.999 103.353-56L59.061 0z"
                                            data-name="Trazado 170"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div  >
                            <h3 className="text-[#62A0E3] font-montserratExtrabold tex-5xl flex justify-center py-4">
                                Afternoon Age 5-6
                            </h3>
                            <div className="relative hover:scale-105 transform transition-all duration-300 hover:z-10">

                                <div className="  font-montserrat font-bold bg-white  rounded-md  px-8 py-3 card-content">
                                    Hi Liz! 🌞 How did your drawing of the sun turn out? Can you think of other things that give us light? Maybe lamps or fireflies? (Bonus: Fireflies use their own special light to glow in the dark!)
                                </div>
                                <div className="speech-bubble-tip">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.353"
                                        height="65"
                                    >
                                        <path
                                            fill="#fff"
                                            fillRule="evenodd"
                                            d="M59.061 0S48.456 47.001 0 65c0 0 52.672-1.999 103.353-56L59.061 0z"
                                            data-name="Trazado 170"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div  >
                            <h3 className="text-[#62A0E3] font-montserratExtrabold tex-5xl flex justify-center py-4">
                                Morning Age 7-8
                            </h3>
                            <div className="relative hover:scale-105 transform transition-all duration-300 hover:z-10">

                                <div className="  font-montserrat font-bold bg-white  rounded-md  px-8 py-3 card-content">
                                    Hello Mike! 🌋 How was your adventure into Earth’s layers? Can you name them? Try making a model of Earth with layers using playdough or clay! (Bonus: The mantle flows slowly, kind of like honey, even though it’s very hot!)<div className="speech-bubble-tip">
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.353"
                                        height="65"
                                    >
                                        <path
                                            fill="#fff"
                                            fillRule="evenodd"
                                            d="M59.061 0S48.456 47.001 0 65c0 0 52.672-1.999 103.353-56L59.061 0z"
                                            data-name="Trazado 170"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div  >
                            <h3 className="text-[#62A0E3] font-montserratExtrabold tex-5xl flex justify-center py-4">
                                Afternoon Age 7-8
                            </h3>
                            <div className="relative hover:scale-105 transform transition-all duration-300 hover:z-10">

                                <div className="  font-montserrat font-bold bg-white  rounded-md  px-8 py-3 card-content">
                                    Hi Mike! 🌋 What did you learn about the Earth’s layers? Can you name the different layers? Maybe you can even create a model of Earth with different layers using playdough or clay! (Bonus: The mantle is so hot that rocks can actually flow like a thick liquid!)
                                </div>
                                <div className="speech-bubble-tip">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="103.353"
                                        height="65"
                                    >
                                        <path
                                            fill="#fff"
                                            fillRule="evenodd"
                                            d="M59.061 0S48.456 47.001 0 65c0 0 52.672-1.999 103.353-56L59.061 0z"
                                            data-name="Trazado 170"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EducationSystem;
