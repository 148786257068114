import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../page/HomePage';
// import Contact from './Pages/Contact';
// import About from './Pages/About';
import Register from '../page/Register';
import Login from '../page/Login';
import Dashboard from '../page/Dashboard';
import ThankYou from '../page/ThankYou';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/thankYou' element={<ThankYou />} />
        {/* <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                 */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
