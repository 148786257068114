import React from 'react';
import './assets/css/style.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Router from './config/router';
const App = () => {
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
