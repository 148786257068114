import { useEffect, useState } from 'react';
import stripeService from '../services/stripe-service';
import Navbar from '../layouts/navBar';
import Footer from '../layouts/Footer';
import toast from 'react-hot-toast';
import subscriptionHistoryService from '../services/subscriptionHistory-service';
import { useNavigate } from 'react-router-dom';
import userService from '../services/user-service';

function Dashboard() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if (!accessToken && !refreshToken) {
      navigate('/');
      return;
    }

    const fetchUser = async () => {
      try {
        const response = await userService.getMyUser();
        setUser(response);
      } catch (error) {
        console.error('Error fetching user details:', error);
        toast.error('Failed to load user details.');
      }
    };

    const fetchPaymentHistory = async () => {
      try {
        const response =
          await subscriptionHistoryService.getSubscriptionsHistory();
        setPaymentHistory(response);
      } catch (error) {
        console.error('Error fetching payment history:', error);
        toast.error('Failed to load payment history.');
      }
    };

    fetchUser();
    fetchPaymentHistory();
  }, [navigate]);

  const handleManageSubscription = async () => {
    try {
      const response = await stripeService.manage();
      window.location.href = response.portalLink;
    } catch (error) {
      console.error('Error redirecting to subscription management:', error);
      toast.error('Failed to redirect to subscription management.');
    }
  };

  return (
    <div className='bg-[#FFE4B5]'>
      <Navbar />
      <div className='container mx-auto py-16'>
        {/* Subscription Details Section */}
        {user ? (
          <div className='bg-[#35C5C0] shadow-md rounded-lg p-8 mb-8'>
            <h2 className='text-3xl font-montserratBold text-white mb-6'>
              Subscription Status
            </h2>
            <p className='font-montserratExtrabold text-lg text-white'>
              <span className='font-bold'>Status:</span>{' '}
              {user.isSubscribed === true ? 'Subscribed' : 'Not Subscribed'}
            </p>
            {user.isSubscribed && user.subscriptionId && user.Subscription && (
              <>
                <p className='font-montserratExtrabold text-lg text-white mt-3'>
                  <span className='font-bold'>Subscription Tier: </span>
                  {user.Subscription.title} Tier
                </p>
                <button
                  className='bg-[#FF7799] text-white font-montserratBold py-3 px-6 rounded-full mt-6 hover:scale-105 transition-transform duration-300'
                  onClick={handleManageSubscription}
                >
                  Manage Subscription
                </button>
              </>
            )}
            {!user.isSubscribed && (
              <button
                className='bg-[#62A0E3] text-white font-montserratBold py-3 px-6 rounded-full mt-6 hover:scale-105 transition-transform duration-300'
                onClick={() => navigate('/#pricing')}
              >
                See Pricing
              </button>
            )}
          </div>
        ) : (
          <p>Loading subscription details...</p>
        )}

        {/* Payment History Section */}
        <div className='bg-white shadow-md rounded-lg p-8'>
          <h2 className='text-3xl font-montserratBold text-[#62A0E3] mb-6'>
            Payment History
          </h2>
          {paymentHistory.length > 0 ? (
            <table className='w-full table-auto'>
              <thead>
                <tr className='bg-[#FF7799] text-white'>
                  <th className='px-6 py-4 font-montserratBold text-lg'>
                    Date
                  </th>
                  <th className='px-6 py-4 font-montserratBold text-lg'>
                    Subscription
                  </th>
                  <th className='px-6 py-4 font-montserratBold text-lg'>
                    Amount
                  </th>
                  <th className='px-6 py-4 font-montserratBold text-lg'>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment) => (
                  <tr key={payment.id} className='text-center'>
                    <td className='border-t px-6 py-4'>
                      {new Date(payment.paymentDate).toLocaleDateString(
                        'en-GB',
                        {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        }
                      )}
                    </td>
                    <td className='border-t px-6 py-4 text-md'>
                      {payment.Subscription?.title || 'N/A'}
                    </td>
                    <td className='border-t px-6 py-4 text-md'>
                      ${parseFloat(payment.amount).toFixed(2)}
                    </td>
                    <td className='border-t px-6 py-4 text-md'>
                      {payment.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className='font-montserratExtrabold text-md'>
              No payment history available.
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
