import { Link, useNavigate } from 'react-router-dom';
import signImage from '../assets/signImage.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { initalValueRegister, userSchema } from '../Constants/ValidationValue';
import Navbar from '../layouts/navBar';
import userService from '../services/user-service';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

const Register = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if (accessToken || refreshToken) {
      navigate('/');
    }
  }, [navigate]);

  const onFormSubmit = async (values, { setSubmitting, resetForm }) => {
    if (values.password === values.confirmPassword) {
      const payload = { ...values };
      delete payload['confirmPassword'];
      delete payload['terms'];

      try {
        const response = await userService.registerUser(payload);
        if (response?.error) {
          toast.error(response?.error);
        } else {
          toast.success(response?.message);
          resetForm();
          navigate('/login');
        }
      } catch (error) {
        console.error('Error during sign up:', error);
        toast.error('An error occurred during sign up');
      } finally {
        setSubmitting(false);
      }
    } else {
      toast.error('Password and confirm password do not match');
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className='bg-[#FFE4B5] min-h-screen flex flex-col'>
        <Navbar />
        <div className='container mx-auto flex-grow'>
          <h1 className='text-[#62A0E3] text-6xl font-bold flex justify-center mt-8'>
            Register
          </h1>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 p-4'>
            <div className='flex justify-center items-center'>
              <img
                src={signImage}
                alt='Sign Up'
                className='md:mt-20 mt-5 w-3/4 md:w-full hidden md:block'
              />
            </div>

            <Formik
              initialValues={initalValueRegister}
              validationSchema={userSchema}
              onSubmit={onFormSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className='flex flex-col justify-center'>
                  <div className='md:mt-20 mt-5'>
                    {/* First Name Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='firstName'
                        name='firstName'
                        type='text'
                        autoComplete='given-firstName'
                        placeholder='Your Name'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.firstName && touched.firstName
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='firstName'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Last Name Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='lastName'
                        name='lastName'
                        type='text'
                        autoComplete='given-lastName'
                        placeholder='Last Name'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.lastName && touched.lastName
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='lastName'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Child Name Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='childName'
                        name='childName'
                        type='text'
                        autoComplete='given-childName'
                        placeholder="Child's Name"
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.childName && touched.childName
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='childName'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Child Age Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='childAge'
                        name='childAge'
                        type='text'
                        autoComplete='given-childAge'
                        placeholder="Child's Age"
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.childAge && touched.childAge
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='childAge'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Username Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='username'
                        name='username'
                        type='text'
                        autoComplete='given-username'
                        placeholder='Username'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.username && touched.username
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='username'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Email Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='email'
                        name='email'
                        type='email'
                        autoComplete='given-email'
                        placeholder='Your email'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.email && touched.email
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Password Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='password'
                        name='password'
                        type='password'
                        autoComplete='given-password'
                        placeholder='Password'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.password && touched.password
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='password'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Confirm Password Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='confirmPassword'
                        name='confirmPassword'
                        type='password'
                        autoComplete='given-confirmPassword'
                        placeholder='Confirm password'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.confirmPassword && touched.confirmPassword
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='confirmPassword'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Phone Field */}
                    <div className='mt-2.5 md:px-0 px-4'>
                      <Field
                        id='phoneNumber'
                        name='phoneNumber'
                        type='text'
                        autoComplete='given-phoneNumber'
                        placeholder='Phone number'
                        className={`block w-full border-l-4 px-3.5 py-2 outline-none ${
                          errors.phoneNumber && touched.phoneNumber
                            ? 'border-red-500'
                            : 'border-[#62A0E3]'
                        } placeholder:font-montserratBold placeholder:text-[#35C5C0]`}
                      />
                      <ErrorMessage
                        name='phoneNumber'
                        component='div'
                        className='text-red-600'
                      />
                    </div>

                    {/* Terms and Conditions Checkbox */}
                    <div className='flex items-center mt-4 mb-4 md:px-0 px-4'>
                      <Field
                        id='terms'
                        name='terms'
                        type='checkbox'
                        className='w-5 h-5 text-blue-600 font-montserrat'
                      />
                      <label
                        htmlFor='terms'
                        className='ms-2 text-sm font-medium font-montserrat'
                      >
                        I accept{' '}
                        <Link to='/terms' className='text-[#0080FF]'>
                          Terms and Service
                        </Link>{' '}
                        &{' '}
                        <Link to='/privacy' className='text-[#0080FF]'>
                          Privacy Policy
                        </Link>
                      </label>
                      <ErrorMessage
                        name='terms'
                        component='div'
                        className='text-red-600 ml-2'
                      />
                    </div>

                    {/* Submit Button */}
                    <div className='text-center md:text-left'>
                      <button
                        type='submit'
                        className='bg-[#F27253] text-white p-4 w-full md:w-3/4 rounded-full font-bold font-montserratBold text-3xl uppercase'
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Registering...' : 'Register'}
                      </button>

                      <div className='my-4'>
                        <p className='font-bold text-2xl'>
                          Do you already have an account?{' '}
                        </p>
                        <Link
                          to='/login'
                          className='text-[#62A0E3] font-montserratBold font-bold'
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
