import axios from './axios';

const getSubscriptions = async () => {
  try {
    const response = await axios.get('/subscriptions');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const subscriptionService = {
  getSubscriptions,
};

export default subscriptionService;
