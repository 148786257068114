import * as Yup from 'yup';

export const initalValueRegister = {
  firstName: '',
  lastName: '',
  childName: '',
  childAge: '',
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
  terms: false,
};

export const userSchema = Yup.object({
  firstName: Yup.string()
    .min(3, 'First name must be at least 3 characters.')
    .required('First name is required'),
  lastName: Yup.string()
    .min(3, 'Last name must be at least 3 characters.')
    .required('Last name is required'),
  childName: Yup.string()
    .min(3, 'Child name must be at least 3 characters.')
    .required('Child name is required'),
  childAge: Yup.number()
    .typeError('Child age must be a number')
    .min(1, 'Child age must be at least 1')
    .max(18, 'Child age must be at most 18')
    .required('Child age is required'),
  username: Yup.string()
    .min(3, 'Username must be at least 3 characters.')
    .required('Username is required'),
  email: Yup.string()
    .email('Invalid email format')
    .matches(/^(?!.*@[^,]*,)/, 'Email cannot contain commas')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
  phoneNumber: Yup.string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Phone number is not valid')
    .required('Phone number is required'),
  terms: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
});

export const initalValuelogin = {
  email: '',
  password: '',
};

export const loginSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .matches(/^(?!.*@[^,]*,)/, 'Email cannot contain commas')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
});
